@import "../../themes/variables.sutti.scss";

.file-uploader {
  .selector {
    display: flex;
    justify-content: flex-start;

    .delete-button {
      margin: 18px 0;
    }
  }

  .file {
    max-width: 100%;
  }
}
