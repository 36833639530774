@import "../../themes/variables.sutti.scss";

.dx-field {
  margin: 15px !important;
}

.sendButton {
  margin: 1rem 0 !important;
  background-color: $base-accent;
  color: #ffffff;
  width: 120px;
}

.nbSms {
  float: right;
}