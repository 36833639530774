* {
  margin: 0;
  padding: 0;
}

.contentIcon {
  align-items: center;
  text-align: center;
}

.containerWelcome {
  position: relative;
  align-items: center;
  text-align: center;
}

.containerWelcome .header {
  text-align: center;
  height: 180px;
}

/* Hello! */
.dx-theme-material-typography h1 {
  font-size: 54px;
  font-weight: 700;
  position: absolute;
  width: 669px;
  height: 74px;
  left: calc(50% - 730px / 2);
  font-family: "Open Sans", sans-serif;
  top: 34px;
  font-style: normal;
  line-height: 74px;
  letter-spacing: 0px;
  text-align: center;
  color: #496c6b;
}

@media (max-width: 800px) {
  .dx-theme-material-typography h1 { font-size: 40px;}
}

@media (min-width: 250px) and (max-width: 600px) {
  .dx-theme-material-typography h1 { font-size: 25px; left: calc(50% - 650px / 2);}
  .dx-theme-material-typography .paragraphe { font-size: 15px; }
}

.paragraphe {
  position: absolute;
  width: 360px;
  height: 33px;
  top: 112px;
  left: calc(50% - 370px / 2);
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.135338px;
  color: #919191;
}

.grid{
  max-width: 800px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  text-align: center;
  align-items: center;
}

.grid a:hover {
  color :#B3C10F;
}

@media (min-width: 600px) {
  .grid { grid-template-columns: repeat(2, 1fr); }
}

@media (min-width: 900px) {
  .grid { grid-template-columns: repeat(3, 1fr); }
}

/* Rectangle */
.rectangle {
  width: 239px;
  height: 169px;
  top: 27.47%;
  bottom: 54.74%;
  background: #f7f7f7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.rectangle span {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.13533835113048553px;
  text-align: center;
  color: #919191;
}

.rectangle img {
  width: 70%;
  height: 74%;
  padding: 25px 0 5px; // inside the boxes
}

/* Copyrigth 2021 */
.footer {
  font-family: "Lucida Grande", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.09022556245326996px;
  text-align: center;

  color: #919191;

  mix-blend-mode: normal;
  opacity: 0.4;
  margin-top: 40px;
}

@media (min-width: 300px) and (max-width: 1024px) {
  .contentIcon {
    display: flex;
    justify-content: center;
    text-align: center;
  }

 .rectangle {
    margin: 10px;
  }

  .footer {
    margin-top: 40px;
  }
  
}
