@import "../../themes/variables.sutti.scss";


.product-container {
  padding: 30px 0;
  margin: 0 30px;
  &:not(:first-child) {
    border-top: 1px solid $base-border-color;
  }
  .product-id {
    font-weight: 700;
    font-size: 24px;
    margin-top: 15px;
  }
  .current-price {
    font-size: 18px;
    padding: 30px 0;
    span {
      margin-right: 8px;
    }
    .value {
      font-size: 20px;
    }
  }
  .product-prices-form {
    .form {
    display: flex;
    justify-content: flex-start;
    align-items: center;

      .button {
        color: $base-accent;
        background-color: transparent;
        border: none !important;
        box-shadow: none !important;
        min-width: unset;
        margin: 0 !important;
        margin-right: 15px !important;
        margin-bottom: 5px !important;
        align-self: flex-end;
        
        .dx-button-text {
          font-weight: 900;
        }
      }
    }
  }
  .bold {
    font-weight: 700;
  }
  .add-btn {
    margin: 30px auto 15px;
  }
}
.button {
  min-width: 25%;
}
.back-btn {
  background: none;
  border: none;
  box-shadow: none;
  color: $base-accent;
  &:hover {
    box-shadow: none;
  }
}
.handle-btns {
  display: flex;
  justify-content: flex-end;
}
.contentBlock {
  display: flex;
  margin: 20px 40px 20px 40px;
  & > * {
    margin-right: 1rem;
  }
  img {
    max-width: 123px;
  }
}

@media (max-width: 600px) {
  .product-container .product-prices-form .form { 
    flex-direction: column-reverse;
    flex-wrap: wrap;
    .button {
      background-color: $base-accent;
      color: #fff;
      margin-top: 15px !important;
      min-width: 50px;
    }
  }
}