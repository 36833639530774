@import "../../themes/variables.sutti.scss";

.userinfo {
  display: flex;
  align-items: center;

  .image-container {
    overflow: hidden;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin: 0 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

    .user-image {
      width: 100%;
      height: 100%;
    }
    .text-image {
      width: 100%;
      height: 100%;
      padding-top: 4px;
      text-align: center;
      color: #ffffff;
    }
  }

  .username {
    font-size: 14px;
    margin: 0 9px;
    color: $base-text-color;
  }
}
.contentBlock {
  display: flex;
  margin: 20px 40px 20px 40px;
  & > * {
    margin-right: 1rem;
  }
}

