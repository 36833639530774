@import "../../themes/variables.sutti.scss";


h5 {
  margin-top: 15px;
}

.flexbox-alert{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color :$base-accent;
  padding: 10px;

  .dx-icon{
    color :$base-accent;
  }
}


.userinfo {
  display: flex;
  align-items: center;

  .image-container {
    overflow: hidden;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin: 0 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

    .user-image {
      width: 100%;
      height: 100%;
    }
    .text-image {
      width: 100%;
      height: 100%;
      padding-top: 4px;
      text-align: center;
      color: #ffffff;
    }
  }

  .username {
    font-size: 14px;
    margin: 0 9px;
    color: black;
  }
}

.userinfoselected {
  display: flex;
  align-items: center;
  background-color: red;

  .image-container {
    overflow: hidden;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin: 0 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

    .user-image {
      width: 100%;
      height: 100%;
    }
    .text-image {
      width: 100%;
      height: 100%;
      padding-top: 4px;
      text-align: center;
      color: #ffffff;
    }
  }

  .username {
    font-size: 14px;
    margin: 0 9px;
    color: black;
  }
}
.contentBlock {
  display: flex;
  margin: 20px 40px 20px 40px;
  & > * {
    margin-right: 1rem;
  }
}

.dx-field {
  margin: 30px 0 40px 0;
}
.numberInfo {
  margin-top: 0;
}
.actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width:100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  & > * {
    margin-right: 1rem;
  }
}

.dx-icon-arrowdown{
  font-size: 28px  !important;
  color:#ffffff !important;
  background-color: #496a6c ;
  padding: 0.5em
}

.dx-icon-arrowup{
  font-size: 28px  !important;
  color:#ffffff !important;
  background-color: #496a6c ;
  padding: 0.5em
}