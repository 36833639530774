@import "../../themes/variables.sutti.scss";


.flexbox{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.display-value .dx-texteditor-input {  
  color: white !important;  
}  

.dropdown{
  .dx-dropdowneditor-icon{
    color : $base-accent ;
  }
}