@import "../../themes/variables.sutti.scss";

.loader-ctn {
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    border: 10px solid $base-border-color;
    border-top: 10px solid $base-accent;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 1s linear infinite;
  }
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 