@import "../../themes/variables.sutti.scss";

.button {
  min-width: 200px;
  margin: 0 !important;
  color: #fff;
  margin-right: 1rem !important;
  margin-bottom: 1rem !important;
}
.loader {
  color: "#ffffff";
}

.primary{
  background-color: $base-accent;
  &:hover {
    background-color: darken($base-accent, 4);
    color: #fff;
  }
  &:focus {
    background-color: darken($base-accent, 4);
  }
}

.secondary{
  background-color: $base-grey;
  &:hover {
    background-color: darken($base-grey, 4);
    color: #fff;
  }
  &:focus {
    background-color: darken($base-grey, 4);
  }
}