@import "../../themes/variables.sutti.scss";

.smsButton {
  margin-bottom: 1rem !important;
  margin-left: 0 !important;
  background-color: $base-accent;
  color: #ffffff;
  width: 120px;
}

.cancelButton {
  margin-bottom: 1rem !important;
  margin-left: 0 !important;
  background-color: $base-grey;
  color: #ffffff;
  width: 120px;
}

.flex-buttons {
  display: flex;
  justify-content: center;
  flex-direction: row;
  column-gap: 10px;
}

.flex-row-wrap{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  column-gap: 60px;
  padding-bottom: 10px;
}


.flex-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-bottom: 10px;
}

